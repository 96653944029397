import React, { useState } from "react";
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Form from "../components/form"


import Seo from "../components/seo"

export default function index() {
  
    return (
  <>
      <Seo title="Launch Turkey" metaDescription="Launch Turkey is help people to launch their own entity in Turkey easily." />
      <body>
        <Navbar />
        <Form />
        <Footer />
      </body>
  </>
  )
}
