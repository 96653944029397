import React, { useState } from "react";
import Airtable from "airtable";
import { navigate } from "gatsby";


var base = new Airtable({ apiKey: 'keyWbVJIPOygPWYiw' }).base('app4bAzsyhG1umDoz');

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitButton = e.target.querySelector('button[type="submit"]');
    submitButton.style.display = "none";
    const waitingMessage = document.createElement("p");
    waitingMessage.textContent = "Please wait...";
    waitingMessage.className = "paragraph-25"; // Add class 

    e.target.appendChild(waitingMessage);
    base('Table 1').create(
      {
        "Name": name,
        "email": email
      },
      function (err, record) {
        if (err) {
          console.error(err);
          alert("An error occurred. Please try again later.");
          return;
        }
        console.log(record.getId());
        alert("Form submitted successfully!");
        setName("");
        setEmail("");
        navigate("/download-guide");
      }
    );
  };

  return (
    <>
            <div class=" us cta wf-section">
                <div class="section-container-lg w-container">
                <div class="call-to-action">
                    <div class="div-block-377">
                    <h2 class="heading-86 mb-6">Remote Hiring Guide for Startups</h2>
                    <p class="paragraph-25">
                        Start, grow, and manage your business. We're with you each step
                        of the way.{" "}
                    </p>
                    </div>
                </div>
                </div>
            </div>
        
        <div className=" wf-section">
        <div className="section-container-lg w-container">
            <div className="w-layout-grid new-grid">
            <div
                id="w-node-_1270962b-ef15-da0c-2060-1b9647384743-d0daadfb"
                className="panel"
            >
                <div className="panel-txt-container justify-center">
                <div>
                <form onSubmit={handleSubmit} className="w-full max-w-lg">
                <div className="mb-6">
                    <label className="block text-gray-200 font-bold mb-2" htmlFor="name">
                    Name
                    </label>
                    <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="John Doe"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-200 font-bold mb-2" htmlFor="email">
                    Email
                    </label>
                    <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="johndoe@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="flex justify-center">
                    <button
                    className="btn btn-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    >
                    Submit
                    </button>
                </div>
                </form>
                <p className="paragraph-25">*We'll use your email for our marketing goals</p>

                </div>
                </div>
                <div className="panel-img-container">
                <img
                    src="https://res.cloudinary.com/avomeraydin/image/upload/v1677591491/Screen_Shot_2023-02-28_at_16.38.02_eicpw3.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 85vw, (max-width: 767px) 81vw, (max-width: 991px) 85vw, (max-width: 1279px) 44vw, 467px"
                    srcSet="https://res.cloudinary.com/avomeraydin/image/upload/v1677591491/Screen_Shot_2023-02-28_at_16.38.02_eicpw3.png 500w, https://res.cloudinary.com/avomeraydin/image/upload/v1677591491/Screen_Shot_2023-02-28_at_16.38.02_eicpw3.png 1040w"
                    alt=""
                    className="panel-img"
                />
                </div>
            </div>
            </div>
        </div>
        </div>
    </>
  );
};

export default Form;
